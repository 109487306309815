<div>
    <div class="modal fade show imageCropPopUp tr-modal" id="cropImageModal" tabindex="-1" role="dialog"
        aria-labelledby="cropImageModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title" id="cropImageModalTitle">{{ 'profile.add_vc' | translate }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCancel()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="purchaseVinoForm">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'profile.vc' | translate }}</mat-label>
                            <input matInput formControlName="vinoCoins" placeholder="" (ngModelChange)="onVinoChange()">
                            <mat-error class="error-msg" *ngIf="purchaseVinoForm.get('vinoCoins').invalid">{{ 'profile.vc_valid' | translate }}</mat-error>
                        </mat-form-field>
                        <div class="vino-total">
                            <span>
                              {{ 'profile.vc_to_amt_total' | translate }}: &nbsp; 
                            </span>  
                            <p>
                              <span *ngIf="userType != 'Business User'">{{ inclVatVinoTotal | currency:'EUR':'symbol':'.2-2':langCode }}</span>
                              <span *ngIf="userType == 'Business User'">{{ exclVatVinoTotal | currency:'EUR':'symbol':'.2-2':langCode }} {{ 'excl_vat' | translate }}<br>
                                <span class="incl-vino-total"><span>( </span>{{ inclVatVinoTotal | currency:'EUR':'symbol':'.2-2':langCode }} {{ inclVatStatement }}<span> )</span></span>
                              </span>
                            </p>  
                            <span class="vino-amount-info" tooltip="{{ vcCalculationStatement }}" placement="auto">
                              <mat-icon aria-hidden="false" fontIcon="info"></mat-icon>
                            </span>
                        </div>
                        <div class="d-flex content-center">
                            <button type="submit" class="btn tr-btn-primary text-center" (click)="onPayment()"
                                [disabled]="!purchaseVinoForm.valid">{{ 'profile.pay' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>