import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { createCommunityPostMutation, createCommunityQuery, createPostComment, deleteCommunityPost, deleteCommunityQuery, deletePostComment, inviteFriendToCommunityQuery, joinCommunityQuery, joinOfficialCommunity, leaveCommunityQuery, reactToComment, reactToPost, rejectCommunityInvitation, updateCommunity, updateCommunityPost, updatePostComment } from '../graphql/communitiesMutations.graphql';
import { getOwnCommunitiesQuery, searchCommunityQuery, getCommunityQuery, getOwnCommunitiesListQuery, getCommunityPostQuery, getPostComments, getPostReactions, getCommentReactions } from '../graphql/communitiesQueries.graphql'
import { BehaviorSubject, Subject } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { muteUser, muteUserComment, muteUserPost, removeUserFromCommunity, unmuteUser, unmuteUserComment, unmuteUserPost } from 'src/app/graphql/mutations.graphql';
import LoadingIndicatorService from 'src/app/sharedServices/loading-indicator.service';
import { Communities } from '../models/communities.model';
import { ToastService } from 'src/app/sharedServices/toast.service';

@Injectable({
  providedIn: 'root'
})
export class CommunitiesService {
fetchCommunities = new Subject();

constructor(
  private apollo: Apollo,
  public loadingIndicator: LoadingIndicatorService,
  public translate: TranslateService,
  public amplifyService: AmplifyService,
  private router: Router,
  private toastService: ToastService
) { }

tempVar: any;

getOwnCommunities() {
  const community = this.apollo.watchQuery<any>({
    // fetchPolicy: "no-cache",
    fetchPolicy: "network-only",
    query: getOwnCommunitiesQuery
  })
  return community;
}

getOwnCommunitiesList() {
  const community = this.apollo.watchQuery<any>({
    // fetchPolicy: "no-cache",
    // fetchPolicy: "network-only",
    query: getOwnCommunitiesListQuery
  })
  return community;
}

createCommunity(communities: Communities, key, file) {
  return this.apollo.mutate({
    mutation: createCommunityQuery,
    variables: {
      communityName: communities.communityName,
      communityDescription: communities.communityDescription,
      communityPicture: communities.communityPicture,
      communityLocation: communities.communityLocation,
      communityDisclosure: communities.communityDisclosure
    },
  });
}

updateCommunity(communities: Communities) {
  return this.apollo.mutate({
    mutation: updateCommunity,
    variables: {
      community_name: communities.communityName,
      description: communities.communityDescription,
      picture: communities.communityPicture,
      community_location: communities.communityLocation,
      is_public: communities.communityDisclosure,
      id: communities.community_id,
    }
  });
}

deleteCommunity(id) {
  this.apollo.mutate<any>({
    mutation: deleteCommunityQuery,
    variables: {
      id: id
    },
  }).subscribe(({ data }) => {
    this.toastService.showSuccess('Community Deleted Successfully.');
    this.router.navigate(['/communities']);
  });
}

getCommunitiesByName(searchedText) {
  const communitiesList = this.apollo.watchQuery<any>({
    // fetchPolicy: "no-cache",
    query: searchCommunityQuery,
    variables: {
      name: searchedText
    }
  });
  return communitiesList;
}

getCommunityById(id) {
  const communityById = this.apollo.watchQuery<any>({
    //fetchPolicy: "no-cache",
    fetchPolicy: "network-only",
    query: getCommunityQuery,
    variables: {
      id: id
    }
  });
  return communityById;
}

inviteFriendToCommunity(community_id, friend_id) {
  this.apollo.mutate<any>({
    mutation: inviteFriendToCommunityQuery,
    variables: {
      community_id: community_id,
      friend_id: friend_id

    },

  }).subscribe(({ data }) => {
      console.log('invite', data);
  }, (error) => {
    console.log('there was an error sending the query', error);
  })
}

acceptCommunityInvitation(id, isJoinBySearch) {
  this.apollo.mutate<any>({
    mutation: joinCommunityQuery,
    variables: {
      id: id,
      join_by_search: isJoinBySearch
    },
  }).subscribe(({ data }) => {
    this.getOwnCommunities().valueChanges.subscribe();
    this.router.navigate(['/communities/' + data.joinCommunity]);
  });
}

rejectCommunityInvitation(communityId) {
  this.apollo.mutate<any>({
    mutation: rejectCommunityInvitation,
    variables: {
      communityId: communityId,
    },
  }).subscribe(({ data }) => {
    this.loadingIndicator.hide();
    this.getOwnCommunities().valueChanges.subscribe();
  }, (error) => {
    console.log('there was an error sending the query', error);
  })
}

uploadCommunityPic(key, file) {
  if (key != undefined) {
    this.loadingIndicator.show();
    this.amplifyService.storage().put(key, file, {
      level: 'public',
      contentType: 'image/*'
    }).then(result => { }).catch(err => console.log(err));
  } else {
    alert(this.translate.instant("PictureFormatSelection"));
  }
}

joinCommunity(id, isJoinBySearch) {
  return this.apollo.mutate<any>({
    mutation: joinCommunityQuery,
    variables: {
      id: id,
      join_by_search: isJoinBySearch
    },
  });
}

leaveCommunity(id) {
  return this.apollo.mutate<any>({
    mutation: leaveCommunityQuery,
    variables: {
      id: id
    },
  });
}

getCommunityPosts(communityId, disableCache) {
  let cache: any = 'cache-first';
  if (disableCache) {
    cache = 'network-only;'
  }
  const posts = this.apollo.watchQuery<any>({
    fetchPolicy: cache,
    query: getCommunityPostQuery,
    variables:{
      community_id: communityId
    }
  })
  return posts;
}

createCommunityPost(postContent, imageRef, communityId) {
  this.loadingIndicator.show();
  this.apollo.mutate({
    mutation: createCommunityPostMutation,
    variables: {
      community_id: communityId,
      body: postContent,
      image: imageRef,
    },
    update: (cache, {data}: any) => {
      const existingPosts: any = cache.readQuery({
        query: getCommunityPostQuery,
        variables: {
          community_id: communityId
        }
      })
      const newPost = data.createPost
      cache.writeQuery({
        query: getCommunityPostQuery,
        variables: {
          community_id: communityId
        },
        data: { getPosts: [newPost, ...existingPosts.getPosts.posts? existingPosts.getPosts.posts:[]] }
      })
    }
  }).subscribe((newPost) => {
    this.loadingIndicator.hide();
  })
}

updateCommunityPost(communityId, postId, postMessage, imageKey) {
  // this.loadingIndicator.show();
  this.apollo.mutate({
    mutation: updateCommunityPost,
    variables: {
      community_id: communityId,
      post_id: postId,
      body: postMessage,
      image: imageKey,
    },
    update: (cache, {data}: any) => {
      const existingPosts: any = cache.readQuery({
        query: getCommunityPostQuery,
        variables: {
          community_id: communityId
        }
      })
      const updatedPosts = existingPosts.getPosts.posts.map(post => {
        if (post.post_id === postId) {
          return post = data.updatePost;
        }
        return post;
      })
      cache.writeQuery({
        query: getCommunityPostQuery,
        variables: {
          community_id: communityId
        },
        data: { getPosts: updatedPosts }
      })
    }
  }).subscribe((data) => {
    this.loadingIndicator.hide();
  });
}

deleteCommunityPost(communityId, postId) {
  this.loadingIndicator.show();
  this.apollo.mutate({
    mutation: deleteCommunityPost,
    variables: {
      community_id: communityId,
      post_id: postId,
    },
    update: (cache) => {
      const existingPosts: any = cache.readQuery({
        query: getCommunityPostQuery,
        variables: {
          community_id: communityId
        }
      })
      const updatedPosts = existingPosts.getPosts.posts.filter(post => (post.post_id != postId));
      cache.writeQuery({
        query: getCommunityPostQuery,
        variables: {
          community_id: communityId
        },
        data: { getPosts: updatedPosts }
      })
    }
  }).subscribe((data) => {
    this.loadingIndicator.hide();
  });
}

getPostReactions(postId) {
  const reactions = this.apollo.watchQuery<any>({
    query: getPostReactions,
    variables:{
      post_id: postId
    }
  })
  return reactions;
}

reactToCommunityPost(communityId, postId, reactiontype, userId) {
  this.loadingIndicator.show();
  this.apollo.mutate({
    mutation: reactToPost,
    variables: {
      community_id: communityId,
      post_id: postId,
      reaction_type: reactiontype
    },
    update: (cache, {data}: any) => {
      const existingReactions: any = cache.readQuery({  
        query: getPostReactions,
        variables: {
          post_id: postId,
        }
      })
      let updated = false;
      let updatedReactions = {
        reactions: []
      };
      if (existingReactions.getPostReactions?.reactions?.length) {
        updatedReactions.reactions = existingReactions.getPostReactions.reactions.map(reaction => {
          if (reaction.user.id === userId) {
            updated = true;
            return reaction = data.reactToPost;
          }
          return reaction;
        })
      }
      if (!updated) {
        updatedReactions.reactions.push(data.reactToPost);
      }
      cache.writeQuery({
        query: getPostReactions,
        variables: {
          post_id: postId,
        },
        data: { getPostReactions: updatedReactions }
      })
    }
  }).subscribe((data) => {
    // console.log(data);
    this.loadingIndicator.hide();
  });
}

getPostComments(postId) {
  const comments = this.apollo.watchQuery<any>({
    // fetchPolicy: "network-only",
    query: getPostComments,
    variables:{
      post_id: postId
    }
  })
  return comments;
}

createPostComment(communityId, postId, commentContent) {
  this.apollo.mutate({
    mutation: createPostComment,
    variables: {
      community_id: communityId,
      post_id: postId,
      body: commentContent,
    },
    update: (cache, {data}: any) => {
      const existingComments: any = cache.readQuery({
        query: getPostComments,
        variables: {
          post_id: postId
        }
      })
      const newComment = data.createComment;
      cache.writeQuery({
        query: getPostComments,
        variables: {
          post_id: postId
        },
        data: {getComments: [newComment, ...existingComments.getComments.comments?existingComments.getComments.comments:[]]}
      })
    }
  }).subscribe(({data}) => {
    // console.log(data);
    this.loadingIndicator.hide();
  })
}

updatePostComment(postId, commentId, commentContent) {
  this.apollo.mutate({
    mutation: updatePostComment,
    variables: {
      post_id: postId,
      comment_id: commentId,
      body: commentContent,
    },
    update: (cache, {data}: any) => {
      const existingComments: any = cache.readQuery({
        query: getPostComments,
        variables: {
          post_id: postId
        }
      })
      const updatedComments = existingComments.getComments.comments.map(comment => {
        if (comment.comment_id === commentId) {
          return comment = data.updateComment;
        }
        return comment;
      })
      cache.writeQuery({
        query: getPostComments,
        variables: {
          post_id: postId
        },
        data: { getComments: updatedComments }
      })
    }
  }).subscribe(({data}) => {
    // console.log(data);
    this.loadingIndicator.hide();
  })
}

getCommentReactions(commentId) {
  const reactions = this.apollo.watchQuery<any>({
    query: getCommentReactions,
    variables:{
      comment_id: commentId,
    }
  })
  return reactions;
}

reactToPostComment(postId, commentId, reactiontype, userId) {
  this.loadingIndicator.show();
  this.apollo.mutate({
    mutation: reactToComment,
    variables: {
      comment_id: commentId,
      reaction_type: reactiontype,
      post_id: postId,
    },
    update: (cache, {data}: any) => {
      const existingReactions: any = cache.readQuery({
        query: getCommentReactions,
        variables: {
          comment_id: commentId,
        }
      })
      let updated = false;
      let updatedReactions = {
        reactions: []
      }
      if (existingReactions.getCommentReactions?.reactions?.length) {
        updatedReactions.reactions = existingReactions.getCommentReactions.reactions.map(reaction => {
          if (reaction.user.id === userId) {
            updated = true;
            return reaction = data.reactToComment;
          }
          return reaction;
        })
      }
      if (!updated) {
        updatedReactions.reactions  .push(data.reactToComment)
        // existingReactions.getCommentReactions.reactions.push(data.reactToPost);
      }
      cache.writeQuery({
        query: getCommentReactions,
        variables: {
          comment_id: commentId,
        },
        data: { getCommentReactions: updatedReactions }
      })
    }
  }).subscribe((data) => {
    this.loadingIndicator.hide();
  });
}

deletePostComment(postId, commentId) {
  this.apollo.mutate({
    mutation: deletePostComment,
    variables: {
      post_id: postId,
      comment_id: commentId,
    },
    update: (cache) => {
      const existingComments: any = cache.readQuery({
        query: getPostComments,
        variables: {
          post_id: postId
        }
      })
      const updatedComments = existingComments.getComments.comments.filter(comment => (comment.comment_id != commentId));
      cache.writeQuery({
        query: getPostComments,
        variables: {
          post_id: postId
        },
        data: { getComments: updatedComments }
      })
    }
  }).subscribe((data) => {
    this.loadingIndicator.hide();
  })
}

joinOfficialCommunity() {
  return this.apollo.mutate<any>({
    mutation: joinOfficialCommunity,
  });
}

removeUserFromCommunity(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: removeUserFromCommunity,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

muteUser(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: muteUser,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

unmuteUser(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: unmuteUser,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

muteUserPost(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: muteUserPost,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

unmuteUserPost(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: unmuteUserPost,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

muteUserComment(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: muteUserComment,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

unmuteUserComment(userId: string, communityId: string) {
  return this.apollo.mutate<any>({
    mutation: unmuteUserComment,
    variables: {
      user_id: userId,
      community_id: communityId,
    },
  })
}

}
