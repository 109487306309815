import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { getUserLevelInfo } from 'src/app/graphql/queries.graphql';

@Injectable({
  providedIn: 'root'
})
export class SharedApiService {

  constructor(
    private apollo: Apollo,
  ) { }

  getUserLevelInfo(): any {
    return this.apollo.watchQuery<any>({
      fetchPolicy: "no-cache",
      query: getUserLevelInfo,
    });
  }
}
