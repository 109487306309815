import { gql } from 'apollo-angular';

export const getNotifications = gql`
subscription MySubscription($user_id: String!) {
  onCreateNotification (user_id: $user_id) {
    category
    created_timestamp
    event_id
    payload {
      cellar_bk_id
      cellar_bk_nm
      user_wine_id
      wine_name
      community_id
      community_name
      post_id
      comment_id
      sales_id
      bid_detail_id
      sell_price,
      new_level
      vino_coins
    }
    user {
      firstname
      user_id
      lastname
    }
    invoker_user_id
    invoker_lastname
    invoker_firstname
    invoker_companyName
    invoker_userType
    invoker_vatNumber
  }
}
`;
