import { Component, OnInit, Injectable, Input } from '@angular/core';
import { UserProfileDataService } from 'src/app/user-profile/user-profile-data.service';

@Component({
  selector: 'app-vino-coin-balance',
  templateUrl: './vino-coin-balance.component.html',
  styleUrls: ['./vino-coin-balance.component.css']
})

@Injectable({
  providedIn: 'root'
})
export class VinoCoinBalanceComponent implements OnInit {
  @Input() size: any
  @Input() vino: 0
  vinoCoinBalance = 0;
  vinoDigitCount = 2;
  constructor(
    private userProfileDataService: UserProfileDataService
  ) { }

  ngOnInit(): void {
    if (this.vino) {
      this.vinoCoinBalance = this.vino
    } else {
      this.userProfileDataService.currentVinoCoins$.subscribe((data: any) => {
        if (data) {
          this.vinoCoinBalance = parseFloat(data);
          this.vinoDigitCount = String(this.vinoCoinBalance).length;
        } else {
          this.vinoCoinBalance = 0;
        }
      });
    }
  }

}
