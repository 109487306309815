<span class="vino-coin" *ngIf="size == 'large'">
    <img class="vino-coin-image-large" src="../../../../assets/img/vino-coin-image.svg" alt="vino coin image">
    <span class="vino-coin-text d-flex flex-column font-bold">
        <span [ngClass]="vinoDigitCount > 2 ? 'three-digit-number-large' : 'number-large'">{{ vinoCoinBalance }}</span>
        <span class="text-large">VC</span>
    </span>
</span>

<span class="vino-coin" *ngIf="size == 'small'">
    <img class="vino-coin-image-small" src="../../../../assets/img/vino-coin-image.svg" alt="vino coin image">
    <span class="vino-coin-text vino-coin-text-small d-flex flex-column font-bold">
        <span [ngClass]="vinoDigitCount > 2 ? 'three-digit-number-small' : 'number-small'">{{ vinoCoinBalance }}</span>
        <span class="text-small">VC</span>
    </span>
</span>