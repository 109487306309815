<div class="tr-modal-wrapper">
    <div class="modal fade show d-block tr-modal level-up-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <h4 class="mb-0 font-bold">{{ 'common.congratulations' | translate }}</h4>
                    <h4 class="mb-3 font-bold">{{ 'common.level_reached' | translate }} {{ userlevelData.userLevel }}</h4>
                    <div class="row">
                        <div class="col-6">
                            <div class="user-profile">
                                <div class="user-profile-image">
                                    <img class="user-image" [src]="userProfileImage" alt="profile image" *ngIf="userProfileImage">
                                    <div class="user-level-badge">
                                        <img src="../../../../assets/img/level-badge.svg" alt="level badge">
                                        <div class="position-relative">
                                            <span class="level-text" [ngClass]="{ 'two-digit-level': userlevelData.userLevel > 9 }">LV <span class="font-bold level-number">{{ userlevelData.userLevel }}</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="next-challenge d-flex align-items-center" *ngIf="userlevelData.userLevel < 24">
                                <div class="level-badge" [ngClass]="{ 'two-digit-level': userlevelData.userLevel > 9 }">
                                    <img src="../../../../assets/img/next-level-badge-gray.svg">
                                    <span class="next-level-text">LV<span class="font-bold">{{ userlevelData.userLevel + 1 }}</span></span>
                                </div>
                                <div class="challenge-text font-bold">
                                    <span>{{ 'common.next_challenge' | translate }} </span>
                                    <span>{{ allLevelsData['Level_'+(userlevelData.userLevel + 1)]['task'] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-left">
                            <img class="level-up-glass" src="../../../../assets/img/level-up-glasses.svg">
                        </div>
                    </div>
                    <button type="button" class="btn tr-btn-primary text-uppercase mt-4" (click)="closePopup()">{{ 'common.ok_great' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="backdrop"></div>
</div>