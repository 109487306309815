import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';
import HttpService from './http.service';
import { ToastService } from './toast.service';
import { StorageService } from '../core/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements CanActivate {
  private authStatusSource = new BehaviorSubject<boolean>(false);
  currentAuthStatus = this.authStatusSource.asObservable();
  private userSource = new BehaviorSubject<object>({});
  currentUser = this.userSource.asObservable();
  private userNameSource = new BehaviorSubject<string>('');
  currentUserName = this.userNameSource.asObservable();
  private firstNameSource = new BehaviorSubject<string>('');
  currentFirstName = this.firstNameSource.asObservable();
  private imageSource = new BehaviorSubject<string>('');
  currentImage = this.imageSource.asObservable();

  paymentDetails: any = {};
  referralUserId: string = '';
  federatedProvider: string = '';
  federatedLogin: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public amplifyService: AmplifyService,
    private httpService: HttpService,
    private toastService: ToastService,
    private storageService: StorageService,
  ) { }

  canActivate(): any {
    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser({
        bypassCache: false
      })
        .then((user) => {
          if (user) {
            localStorage.setItem("access_token", user.signInUserSession.accessToken.jwtToken);
            if ((user.attributes.hasOwnProperty('custom:userType') && (user.attributes['custom:userType'] !== 'NA'))) {
              this.firstNameSource.next((user.attributes['custom:userType'] == "Business User") ? user.attributes['custom:companyName'] : (user.attributes.name).split(" ", 1));
            } else {
              this.firstNameSource.next((user.attributes.name).split(" ", 1))
            }
            this.userSource.next(user);
            if ((user.attributes.hasOwnProperty('picture') && (user.attributes.picture !== 'No photo uploaded.'))) {
              this.amplifyService.storage().get(user.attributes.picture, {
                level: 'protected',
                contentType: 'image/*'
              }).then(result => {
                this.changeImage(result);
              }).catch(err => {
                console.log(err);
              });
            } else {
              if ((user.attributes.hasOwnProperty('custom:userType') && (user.attributes['custom:userType'] !== 'NA'))) {
                this.changeImage((user.attributes['custom:userType'] == "Business User") ? './assets/img/business-profile.png' : './assets/img/profile.jpg');
              } else {
                this.changeImage('./assets/img/profile.jpg');
              }
            }
            if (!(user.attributes.hasOwnProperty('custom:userType'))) {
              this.authStatusSource.next(false);
              this.router.navigate(['/federated-user-details'], { queryParams: { 'firstTimeLogin': true } });
              resolve(true);
            } else {
              this.authStatusSource.next(true);
              this.paymentDetails = JSON.parse(user.attributes['custom:paymentInfo']);
              // if ((user.attributes['custom:accountType'] == 'Premium Version') && (this.paymentDetails.paymentStatus == 'unPaid')) {
              //   this.router.navigate(['/payment']);
              // }
              resolve(true);
            }
          }
        })
        .catch((error) => {
          this.route.queryParams.subscribe(params => {
            if (params.hasOwnProperty('state') && params['state'].includes('-')) {
              this.federatedLogin = true;
              const stateParam = params['state'];
              const customStateEncoded = stateParam.split('-')[1];
              const customState = decodeURIComponent(customStateEncoded.match(/.{1,2}/g)?.map(byte => `%${byte}`).join(''));
              const federatedDetails = customState.split('=');
              this.referralUserId = federatedDetails[0];
              this.federatedProvider = federatedDetails[1];
              if (this.referralUserId != '001'){
                this.httpService.setReferralUserId(this.referralUserId).subscribe((response) => {
                  if (response?.hasOwnProperty('user_id')){
                    this.referralUserId = response.user_id;
                    this.storageService.setReferralUserDetails(response);
                  }
                });
              }
            }
            if (localStorage.getItem("amplify-redirected-from-hosted-ui") && this.federatedLogin) {
              Auth.federatedSignIn({
                customProvider: this.federatedProvider
              });
            } else {
              this.authStatusSource.next(false);
              // window.location.assign('/home');
              resolve(false);
            }
          });
        });
    });
  }
  changeUserDetails(userName: string) {
    this.userNameSource.next(userName);
  }
  changeUser(user: object) {
    this.userSource.next(user);
  }
  changeUserFirstName(firstName: string) {
    this.firstNameSource.next(firstName);
  }
  changeAuthStatusDetails(authStatus: boolean) {
    this.authStatusSource.next(authStatus);
  }
  changeImage(imageUrl: string) {
    this.imageSource.next(imageUrl);
  }

}
