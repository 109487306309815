/* eslint-disable max-len */
/* eslint-disable lines-between-class-members */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Hub, Auth } from 'aws-amplify';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { AuthenticationService } from './sharedServices/authentication.service';
import { debounceTime, Subscription } from 'rxjs';
import LoadingIndicatorService from './sharedServices/loading-indicator.service';
import { MatDialog } from '@angular/material/dialog';
import LoadingIndicatorComponent from './shared/components/loading-indicator/loading-indicator.component';
import UserDataService from './sharedServices/user-data.service';
import HttpService from './sharedServices/http.service';
import { StripePaymentComponent } from 'src/app/stripe-payment/stripe-payment.component';
import { UserProfileDataService } from 'src/app/user-profile/user-profile-data.service';
import { ToastService } from 'src/app/sharedServices/toast.service';
import { StorageService } from './core/services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export default class AppComponent implements OnInit, OnDestroy {
  authStatus: boolean = false;
  showSessionModal = false;
  viewLoaded = false;
  loaderSub = new Subscription();
  loaderOn = false;
  dialogRef;
  noMarginBottom = false;
  showLevelUpPopup = false;
  showVinoPopup = false;
  levelUpData = {};
  levelsData = {};

  constructor(
    private router: Router,
    private userIdle: UserIdleService,
    public translate: TranslateService,
    public data: AuthenticationService,
    private meta: Meta,
    private loadingIndicator: LoadingIndicatorService,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private httpService: HttpService,
    private stripePaymentComponent: StripePaymentComponent,
    private userProfileDataService: UserProfileDataService,
    private toastService: ToastService,
    private storageService: StorageService,
  ) {
    Hub.listen('auth', async ({ payload: { event } }) => {
      if (event === 'signIn' || event === 'cognitoHostedUI') {        
        this.data.changeAuthStatusDetails(this.authStatus);
        // this.ngZone.run(() => this.router.navigate(['/dashboard'])).then();
      } else {
        this.data.changeAuthStatusDetails(this.authStatus);
      }
    });

    this.meta.addTags([
      { name: 'description', content: 'Tresorvino: This is the description for the Tresorvino' },
      { name: 'keywords', content: 'tresorvino, wine, red wine' },
      { name: 'og:title', content: 'Tresorvino' },
      { name: 'og:description', content: 'Tresorvino: This is the description for the Tresorvino' },
      { name: 'og:image', content: `${window.location.origin}/assets/img/tresorvino-glass.png` },
      { name: 'og:url', content: window.location.origin },
      // { name: 'twitter:title', content: 'Tresorvino' },
      // { name: 'twitter:description', content: 'Tresorvino: This is the description for the Tresorvino' },
      // { name: 'twitter:image', content: window.location.origin + '/assets/img/tresorvino-glass.png' },
      { name: 'twitter:card', content: window.location.origin },
      { name: 'og:site_name', content: 'Tresorvino' },
      { name: 'twitter:card', content: window.location.origin },
    ]);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/login' || event.urlAfterRedirects === '/registration' || event.urlAfterRedirects === '/forgot-password') {
          this.noMarginBottom = true;
        } else {
          this.noMarginBottom = false;
        }
      }
    });
    if (localStorage.getItem("paymentSession")) {
      this.httpService.getVinoBalance().valueChanges.subscribe((response: any) => {
        const data = response.data.getCurrentUserVinoCoins;
        const currentVinoCoins = data.vinoCoins;
        const functionalityCharges = data.functionalityCharges;
        this.userProfileDataService.storeVinoCoins(currentVinoCoins);
        this.userProfileDataService.storeFunctionalityCharges(functionalityCharges)
        let sessionDetails = JSON.parse(localStorage.getItem("paymentSession"));
        this.stripePaymentComponent.getPaymentStatus(sessionDetails, currentVinoCoins).then(
          (response) => {
            (response) ? this.toastService.showSuccess("Payment Successful !") : this.toastService.showError("Payment Failed !")
            localStorage.removeItem("paymentSession");
          });
        this.loadingIndicator.hide();
      })
    }
  }

  ngOnInit(): void {
    this.data.currentAuthStatus.subscribe((authStatus) => {
      if (authStatus) {
        this.authStatus = true;
        this.userIdle.startWatching();
      } else {
        this.authStatus = false;
      }
      setTimeout(() => {
        this.viewLoaded = true;
      }, 1000);
    });
    this.userIdle.onTimerStart().subscribe(() => {});

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      if (this.authStatus) {
        const isTimePresent = this.storageService.getSessionStorage('timerInit');
        if (!isTimePresent) {
          const currentTime = new Date().getTime();
          this.storageService.setSessionStorage('timerInit', currentTime);
        }
        this.showSessionModal = true;
      }
    });

    this.loaderSub = this.loadingIndicator.loaderState.subscribe((state) => {
      if (state && !this.loaderOn) {
        this.dialogRef = this.dialog.open(LoadingIndicatorComponent, { disableClose: true });
      }
      if (!state && this.loaderOn) {
        this.dialogRef.close();
      }
      this.loaderOn = state;
    });
    // this.router.navigate(['/introduction']);

    this.userDataService.getUserAccess$.subscribe(() => {
      this.getUserAccessInfo();
    });
    const userAccessInfo = this.userDataService.getUserAccessInfo();
    if (!userAccessInfo?.length) {
      this.getUserAccessInfo();
    }

    this.userDataService.userLevelUp$.subscribe((data: any) => {
      this.levelUpData = data;
      this.showLevelUpPopup = true;
    })

    this.userDataService.userLevelsInfo$.subscribe((data: any) => {
      this.levelsData = data;
    })

    this.handleApplicationFocus();
  }

  getUserAccessInfo(): void {
    this.httpService.getUserAccessInfo().subscribe((data: any) => {
      this.userDataService.setUserAccessInfo(data);
    });
  }

  onLogOut() {
    Auth.signOut({ global: true })
      .then(() => {
        this.authStatus = false;
        this.showSessionModal = false;
        this.userIdle.resetTimer();
        window.location.assign('/home');
        setTimeout(() => {
          this.clearStorages();
        }, 1000);
      })
      .catch((err) => console.log(err));
  }

  keepLoggedIn() {
    this.showSessionModal = false;
    this.userIdle.resetTimer();
    this.storageService.removeSessionStorage('timerInit');
    this.storageService.removeSessionStorage('timerInitFull');
  }

  clearStorages(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  closeLevelUpPopup(): void {
    this.showLevelUpPopup = false;
  }

  closeVinoCoinPopup(): void {
    this.showVinoPopup = false;
  }

  handleApplicationFocus(): void {
    const self = this;
    document.addEventListener("visibilitychange", function (event) {
      if (self.authStatus) {
        const currentTime = new Date().getTime();
        const timerFifteen = self.storageService.getSessionStorage('timerInit');
        const timerThirty = self.storageService.getSessionStorage('timerInitFull');
        if ((timerFifteen || timerThirty) && !document.hidden) {
          const idealTimeWindow = timerFifteen ? 15 : 30; // time in minutes
          const idealTimeGap = idealTimeWindow * 60 * 1000; // minutes converted to milliseconds
          const oldTime = timerFifteen ?? timerThirty;
          if ((oldTime + idealTimeGap) < currentTime) {
            self.storageService.removeSessionStorage('timerInit');
            self.storageService.removeSessionStorage('timerInitFull');
            self.onLogOut();
          } else {
            self.storageService.removeSessionStorage('timerInitFull');
          }
        } else if (!timerFifteen && document.hidden) {
          self.storageService.setSessionStorage('timerInitFull', currentTime);
        }
      }
    });
  }
  
  ngOnDestroy(): void {
    this.loaderSub.unsubscribe();
  }
}
