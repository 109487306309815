
<mat-form-field [ngClass]="class" [appearance]="appearance">
    <mat-label>{{ fieldLabel }}</mat-label>
    <mat-select [panelClass]="class" [formControl]="dropdownControl" (selectionChange)="onValueSelect($event)" (openedChange)="focusSearchInput($event)" (keydown.space)="onSpacePress($event)">
        <!-- <mat-select-trigger *ngIf="searchRequired">
            {{ dropdownForm.get('trDropdown').value }}
        </mat-select-trigger> -->
        <div class="dropdown-search" *ngIf="searchRequired">
            <input #searchInput type="text" class="form-control" matInput [formControl]="searchControl" (keydown.space)="onSpacePress($event)" placeholder="Search..." />
            <mat-icon (click)="clearInput()" *ngIf="searchControl.value.length">close</mat-icon>
        </div>
        <mat-option *ngFor="let option of filteredOptions" [value]="option?.[labelKey]">
            {{ option?.[labelValue] }}
        </mat-option>
         <!-- Show message when no matches found -->
        <div class="no-match-found" *ngIf="!filteredOptions.length">
            {{ 'common.no_match_found' | translate }}
        </div>
    </mat-select>
    <mat-error *ngIf="isRequired && dropdownControl.errors?.required">
        <span>{{ requiredErrorMsg }}</span>
    </mat-error>
</mat-form-field>    
