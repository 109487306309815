import { gql } from 'apollo-angular';

// Kellerbook related mutations
export const addKellerbookQuery = gql`
  mutation($name: String!, $type: String, $flag: Int) {
    addUserCellar(input: {cellar_bk_df: $flag, cellar_bk_nm: $name, cellar_bk_typ: $type}) {
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
      totalCellarWines
    }
  }
`;

export const updateKellerbookQuery = gql`
  mutation MyMutation(
    $cellar_bk_id: ID!
    $cellar_bk_nm: String
    $cellar_bk_typ: String
    $cellar_bk_df: Int
  ) {
    updateUserCellar(input: {cellar_bk_id: $cellar_bk_id, cellar_bk_nm: $cellar_bk_nm, cellar_bk_typ: $cellar_bk_typ, cellar_bk_df: $cellar_bk_df}) {
      cellar_bk_df
      cellar_bk_id
      cellar_bk_nm
      cellar_bk_typ
    }
  }
`;

export const deleteKellerbookQuery = gql`
  mutation MyMutation($cellar_bk_id: ID!) {
    deleteUserCellar(input: {cellar_bk_id: $cellar_bk_id}) {
      cellar_bk_id
    }
  }
`;

// Wine related mutations
export const addCellarWine = gql`
  mutation(
    $cellar_bk_id: ID!
    $global_wine_id: ID
    $cellar_wine_df: Int
    $cw_storage_temp: String
    $cw_storage: String
    $cw_lid_qlty: String
    $cw_packaging: String
    $cw_available_qty: String
    $cw_lid: String
    $cw_vintage: String
    $cw_price: String
    $cw_liquid_lvl: String
    $cw_currency: String
    $cw_bottle_size: String
    $cw_image: [String],
    $cw_ask_price: String,
    $cw_bid_price: String,
    $cw_description: String,
  ){
    addCellarWine(
      input: {
        cellar_bk_id: $cellar_bk_id
        global_wine_id: $global_wine_id
        cellar_wine_df: $cellar_wine_df
        cw_storage_temp: $cw_storage_temp
        cw_storage: $cw_storage
        cw_lid_qlty: $cw_lid_qlty
        cw_packaging: $cw_packaging
        cw_available_qty: $cw_available_qty
        cw_lid: $cw_lid
        cw_vintage: $cw_vintage
        cw_price: $cw_price
        cw_liquid_lvl: $cw_liquid_lvl
        cw_currency: $cw_currency
        cw_bottle_size: $cw_bottle_size
        cw_image: $cw_image
        cw_ask_price: $cw_ask_price
        cw_bid_price: $cw_bid_price
        cw_description: $cw_description
      }
    ){
      cellar_bk_id
      cellar_wine_df
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid
      cw_lid_qlty
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_ask_price
      cw_bid_price
      user_wine_id
      user_id
      user {
        picture
        lastname
        id
        firstname
        error
      }
      global_wine_id
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        status
        storage
        soil
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        vintage {
          final
          first
        }
        wine_type
        current_price {
          prices {
            bottle_size
            currency
            domain
            likelihood
            region
            vintage
            wine_name
            wine_price
            wine_tax
            wine_url
          }
          average
          day
          difference
          relDiff
          status
        }
      }
      statistics {
        absDifference
        currentValue
        investmentValue
        relDifference
      }
    }
  }
`;

export const addNewWineCellerWineQuery = gql`
  mutation MyMutation(
    $cellar_bk_id: ID!
    $cellar_wine_df: Int

    $cw_storage_temp: String
    $cw_storage: String
    $cw_packaging: String
    $cw_available_qty: String
    $cw_lid: String
    $cw_vintage: String
    $cw_price: String
    $cw_currency: String
    $cw_bottle_size: String
    $cw_image: [String]
    $cw_bid_price: String
    $cw_ask_price: String
    $cw_description: String

    $gw_name: String!
    $gw_soil: String
    $gw_flavor: String
    $gw_predicate_de: String
    $gw_predicate_fr: String
    $gw_grape_variety: String
    $gw_country: String
    $gw_region: String
    $gw_sub_region: String
    $gw_location: String
    $gw_wine_type: String
    $gw_sub_type: String
    $gw_producer_name: String
    $gw_color: String
    $gw_vintage_first: String
    $gw_vintage_final: String
    $gw_barrel: String,
    $gw_cultivation: String,
  ){
    addNewWineAndCellarWine(
      input:
      {
        cellarWine:
        {
          cellar_bk_id: $cellar_bk_id
          cellar_wine_df: $cellar_wine_df
          cw_storage_temp: $cw_storage_temp
          cw_storage: $cw_storage
          cw_packaging: $cw_packaging
          cw_available_qty: $cw_available_qty
          cw_lid: $cw_lid
          cw_vintage: $cw_vintage
          cw_price: $cw_price
          cw_currency: $cw_currency
          cw_bottle_size: $cw_bottle_size
          cw_image: $cw_image
          cw_bid_price: $cw_bid_price
          cw_ask_price: $cw_ask_price
          cw_description: $cw_description
        },
        wine:
        {
          barrel: $gw_barrel,
          color: $gw_color,
          country: $gw_country,
          cultivation: $gw_cultivation,
          currency: $cw_currency,
          flavor: $gw_flavor,
          grape_variety: $gw_grape_variety,
          lid: $cw_lid,
          location: $gw_location,
          temp_name: $gw_name,
          packaging: $cw_packaging,
          predicate_de: $gw_predicate_de,
          predicate_fr: $gw_predicate_fr,
          producer_name: $gw_producer_name,
          quantity: $cw_available_qty,
          region: $gw_region,
          soil: $gw_soil,
          storage: $cw_storage,
          storage_temperature: $cw_storage_temp,
          sub_region: $gw_sub_region,
          wine_type: $gw_wine_type,
          sub_type: $gw_sub_type,
          vintage: {
            first: $gw_vintage_first,
            final: $gw_vintage_final
          }
        }
      }
    ){
      wine_id
      user_wine_id
      cellar_bk_id
    }
  }
`;

export const updateCellarWine = gql`
  mutation MyMutation(
    $cellar_bk_id: ID!
    $user_wine_id: ID!
    $cellar_wine_df: Int
    $cw_storage_temp: String
    $cw_storage: String
    $cw_packaging: String
    $cw_quantity: Int
    $cw_lid: String
    $cw_vintage: String
    $cw_price: String
    $cw_currency: String
    $cw_bottle_size: String
    $cw_image: [String]
    $cw_bid_price: String
    $cw_ask_price: String
    $cw_description: String
    $cw_available_qty: Int
    $cw_on_sale_qty: Int
    $cw_sold_qty: Int
  ){
    updateCellarWine(input: {
      user_wine_id: $user_wine_id
      cellar_bk_id: $cellar_bk_id
      cellar_wine_df: $cellar_wine_df
      cw_storage_temp: $cw_storage_temp
      cw_storage: $cw_storage
      cw_packaging: $cw_packaging
      cw_quantity: $cw_quantity
      cw_lid: $cw_lid
      cw_vintage: $cw_vintage
      cw_price: $cw_price
      cw_currency: $cw_currency
      cw_bottle_size: $cw_bottle_size
      cw_image: $cw_image
      cw_bid_price: $cw_bid_price
      cw_ask_price: $cw_ask_price
      cw_description: $cw_description
      cw_available_qty: $cw_available_qty
      cw_on_sale_qty: $cw_on_sale_qty
      cw_sold_qty: $cw_sold_qty
    }){
      statistics {
        absDifference
        currentValue
        investmentValue
        relDifference
      }
      cellar_bk_id
      cellar_wine_df
      cw_bottle_size
      cw_crtd_time
      cw_currency
      cw_image
      cw_lid
      cw_lid_qlty
      cw_liquid_lvl
      cw_packaging
      cw_price
      cw_quantity
      cw_storage
      cw_storage_temp
      cw_updt_time
      cw_vintage
      cw_description
      global_wine_id
      user_id
      user_wine_id
      user {
        error
        firstname
        id
        lastname
        picture
      }
      global_wine {
        add_info1
        add_info2
        add_info3
        barrel
        color
        country
        cultivation
        currency
        date_added
        date_updated
        flavor
        format
        grape_variety
        id
        label
        current_price {
          prices {
            wine_url
            wine_tax
            wine_price
            wine_name
            region
            domain
            currency
          }
          average
          day
          status
        }
        label_quality
        lid
        lid_quality
        liquid_level
        location
        name
        packaging
        picture
        predicate_de
        predicate_fr
        producer_name
        producer_title
        production_quantity
        quantity
        region
        seller
        soil
        status
        storage
        storage_temperature
        sub_region
        sub_type
        type
        vineyard
        vintage {
          final
          first
        }
        wine_type
      }
    }
  }
`;

export const updateWineAndCellarWine = gql`
  mutation MyMutation(
    $cellar_bk_id: ID!
    $user_wine_id: ID!
    $cellar_wine_df: Int

    $cw_storage_temp: String
    $cw_storage: String
    $cw_packaging: String
    $cw_quantity: Int
    $cw_quantity_str: String
    $cw_lid: String
    $cw_vintage: String
    $cw_price: String
    $cw_currency: String
    $cw_bottle_size: String
    $cw_image: [String]
    $cw_bid_price: String
    $cw_ask_price: String
    $cw_description: String

    $gw_wine_id: ID!
    $gw_name: String
    $gw_soil: String
    $gw_flavor: String
    $gw_predicate_de: String
    $gw_predicate_fr: String
    $gw_grape_variety: String
    $gw_country: String
    $gw_region: String
    $gw_sub_region: String
    $gw_location: String
    $gw_wine_type: String
    $gw_sub_type: String
    $gw_producer_name: String
    $gw_color: String
    $gw_barrel: String,
    $gw_cultivation: String
    $cw_available_qty: Int
    $cw_on_sale_qty: Int
    $cw_sold_qty: Int
    ){
    updateWineAndCellarWine(
      input:
      {
        cellarWine:
        {
          cellar_bk_id: $cellar_bk_id
          cellar_wine_df: $cellar_wine_df
          cw_storage_temp: $cw_storage_temp
          cw_storage: $cw_storage
          cw_packaging: $cw_packaging
          cw_quantity: $cw_quantity
          cw_lid: $cw_lid
          cw_vintage: $cw_vintage
          cw_price: $cw_price
          cw_currency: $cw_currency
          cw_bottle_size: $cw_bottle_size
          cw_image: $cw_image
          user_wine_id: $user_wine_id
          cw_bid_price: $cw_bid_price
          cw_ask_price: $cw_ask_price
          cw_description: $cw_description
          cw_available_qty: $cw_available_qty
          cw_on_sale_qty: $cw_on_sale_qty
          cw_sold_qty: $cw_sold_qty
        },
        wine:
        {
          barrel: $gw_barrel,
          color: $gw_color,
          country: $gw_country,
          cultivation: $gw_cultivation,
          currency: $cw_currency,
          flavor: $gw_flavor,
          grape_variety: $gw_grape_variety,
          lid: $cw_lid,
          location: $gw_location,
          name: $gw_name,
          packaging: $cw_packaging,
          predicate_de: $gw_predicate_de,
          predicate_fr: $gw_predicate_fr,
          producer_name: $gw_producer_name,
          quantity: $cw_quantity_str,
          region: $gw_region,
          soil: $gw_soil,
          storage: $cw_storage,
          storage_temperature: $cw_storage_temp,
          sub_region: $gw_sub_region,
          wine_type: $gw_wine_type,
          sub_type: $gw_sub_type,
          wine_id: $gw_wine_id
        }
      }
    ){
      wine_id
      user_wine_id
      cellar_bk_id
    }
  }
`;

export const deleteCellerWineQuery = gql`
  mutation MyMutation($cellar_bk_id: ID!, $user_wine_id: ID!) {
    deleteCellarWine(input: {cellar_bk_id: $cellar_bk_id, user_wine_id: $user_wine_id}) {
      cellar_bk_id
      user_wine_id
      global_wine_id
    }
  }
`;

// Notifications related mutations
export const markNotificationAsRead = gql`
  mutation MyMutation($eventId: ID!) {
    markNotificationRead(event_id: $eventId)
  }
`;

// Remove user from community
export const removeUserFromCommunity = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  removeCommunityUser(community_id: $community_id, user_id: $user_id)
}
`;

export const muteUser = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  muteUser(community_id: $community_id, user_id: $user_id)
}`
;

export const unmuteUser = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  unmuteUser(community_id: $community_id, user_id: $user_id)
}`
;

export const muteUserPost = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  muteUserPost(community_id: $community_id, user_id: $user_id)
}`
;

export const unmuteUserPost = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  unmuteUserPost(community_id: $community_id, user_id: $user_id)
}`
;

export const muteUserComment = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  muteUserComment(community_id: $community_id, user_id: $user_id)
}`
;

export const unmuteUserComment = gql`
mutation MyMutation($community_id: ID!, $user_id: String!) {
  unmuteUserComment(community_id: $community_id, user_id: $user_id)
}`
;

export const addSalesWine = gql`
mutation MyMutation($cellar_bk_id: ID!, $so_ask_price: String!, $so_quantity: Int, $user_wine_id: ID!, $global_wine_id: ID!, $so_per_bottle_price: String!, $so_pincode: String!, $wine_name: String!) {
  addSalesWine(
    input: {
      cellar_bk_id: $cellar_bk_id, 
      global_wine_id: $global_wine_id,
      so_ask_price: $so_ask_price,
      so_per_bottle_price: $so_per_bottle_price,
      so_pincode: $so_pincode,
      so_quantity: $so_quantity, 
      user_wine_id: $user_wine_id,
      wine_name: $wine_name
    }
  ) {
    sale_id
  }
}
`;

export const addBidMutation = gql`
  mutation MyMutation(
    $bd_ask_price: String!,
    $bd_bid_price: String!,
    $bd_cellar_bk_id: ID!,
    $bd_quantity: Int!,
    $cellar_bk_id: ID!,
    $global_wine_id: ID!,
    $sale_offer_id: ID!,
    $seller_user_id: ID!,
    $user_wine_id: ID!,
    $buyer_pincode: String!,
    $seller_pincode: String!,
    $wine_name: String!
  ) {
    addBid(
      input: {
        bd_ask_price: $bd_ask_price,
        bd_cellar_bk_id: $bd_cellar_bk_id
        bd_quantity: $bd_quantity,
        bd_bid_price: $bd_bid_price, 
        cellar_bk_id: $cellar_bk_id,
        global_wine_id: $global_wine_id, 
        sale_offer_id: $sale_offer_id,
        seller_user_id: $seller_user_id,
        user_wine_id: $user_wine_id,
        buyer_pincode: $buyer_pincode,
        seller_pincode: $seller_pincode,
        wine_name: $wine_name
      }
    ) {
      bd_ask_price
      bd_bid_price
      bd_crtd_date
      bd_crtd_time
      bd_status
      bd_updt_date
      bd_updt_time
      bid_detail_id
    }
  }
`;

export const setBidStatus = gql`
  mutation MyMutation($bd_status: String!, $bid_detail_id: ID!, $sale_offer_id: ID!) {
    updateBidStatus(
      input: {
        bd_status: $bd_status,
        bid_detail_id: $bid_detail_id,
        sale_offer_id: $sale_offer_id
      }
    ) {
      bd_ask_price
      bd_bid_price
      bd_crtd_date
      bd_crtd_time
      bd_quantity
      bd_status
      bd_updt_date
      bd_updt_time
      bid_detail_id
      buyer_user_id
      cellar_bk_id
      sale_offer_id
      user_wine_id
      cellar_wine {
        cellar_bk_id
        cw_ask_price
        cw_available_qty
        cw_bid_price
        cw_currency
        cw_image
        cw_price
        cw_quantity
        cw_sold_qty
        cw_vintage
        user_id
        user_wine_id
        global_wine_id
        global_wine {
          color
          country
          currency
          id
          location
          name
          picture
          producer_title
          producer_name
          production_quantity
          quantity
          status
          sub_region
          sub_type
          type
          vintage {
            final
            first
          }
          wine_type
        }
      }
    }
  }
`;

export const addRating = gql`
  mutation MyMutation ($bid_detail_id: ID!, $sale_offer_id: ID!, $sb_bid_comment: String!, $seller_user_id: ID!, $sb_bid_rating: String!, $sb_bid_header: String!) {
    addRatings(
      input: {
        bid_detail_id: $bid_detail_id,
        sale_offer_id: $sale_offer_id,
        sb_bid_comment: $sb_bid_comment,
        seller_user_id: $seller_user_id,
        sb_bid_rating: $sb_bid_rating,
        sb_bid_header: $sb_bid_header
      }
    ) {
      bid_detail_id
      bid_rating_id
      sale_offer_id
      buyer_user_id
      sb_bid_comment
      sb_bid_rating
      sb_bid_header
      sb_crtd_time
      sb_updt_time
      seller_user_id
    }
  }
`;

export const deleteSaleOffer = gql`
  mutation MyMutation($id: ID!) {
    deleteSalesWine(id: $id) {
      bid_status
    }
  }
`;

export const purchaseFunctionality = gql`
  mutation MyMutation($functionCode: String!, $paymentStatus: String!, $refernceField: String!, $refernceId: ID!, $previousVinoCoins: Float!) {
    subscribeUserFunctionality(
      input: {
        func_cd: $functionCode, 
        status: $paymentStatus, 
        ref_field: $refernceField, 
        ref_id: $refernceId, 
        prev_bal: $previousVinoCoins
      }) {
        transaction_id
        func_cd
        ref_field
        status
        ref_id
        cur_bal
        prev_bal
        vino_coins
        end_date
        timestamp
        user_id
        error
    }
  }
`;