<div class="container">
    <div class="registration-wrapper">
        <div class="row">
            <div class="col-12">
                <mat-stepper class="registration-stepper" [linear]="true" #stepper [selectedIndex]="0">
                    <mat-step [stepControl]="firstFormGroup" label="{{ 'login.register_user_type' | translate }}">
                        <form [formGroup]="firstFormGroup">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.user_type' | translate }}</mat-label>
                                        <mat-select formControlName="userType" panelClass="white-field" (selectionChange)="onUserTypeChange($event)">
                                            <mat-option *ngFor="let type of userTypeList" [value]="type.id">{{ type.value }}</mat-option>
                                        </mat-select>
                                        <mat-error>{{ 'login.register_select_user_type' | translate }}</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <ul style="list-style-type: none;">
                                    <li>
                                        <mat-label class="policy-text">* 
                                            {{ 'login.privacy_statement' | translate }}
                                            <a href="mailto:service@tresorvino.de">service&#64;tresorvino.de</a>
                                        </mat-label>
                                    </li>
                                </ul>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <button type="button" class="btn tr-btn-primary" matStepperNext>Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="secondFormGroup" label="{{ 'login.register_user_details' | translate }}">
                        <form [formGroup]="secondFormGroup">
                            <div class="row">
                                <div class="col-md-4" *ngIf="userType === 'Business User'">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.company_name' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="companyName" maxlength="50" required>
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('companyName')?.errors?.required">{{ 'login.company_name_required' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('companyName')?.errors?.pattern">{{ 'login.values_allowed' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.contact_person_first_name' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="firstName" maxlength="30" required>
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('firstName')?.errors?.required">{{ 'profile.first_name_required' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('firstName')?.errors?.pattern">{{ 'login.values_allowed' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.contact_person_last_name' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="lastName" maxlength="30" required>
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('lastName')?.errors?.required">{{ 'profile.last_name_required' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('lastName')?.errors?.pattern">{{ 'login.values_allowed' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.email_address_contact' | translate }}</mat-label>
                                        <input type="email" matInput formControlName="emailAddress" (blur)="validateUser()" required>
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('emailAddress')?.errors?.required">{{ 'login.email_required_warning' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('emailAddress')?.errors?.email || secondFormGroup.get('emailAddress')?.errors?.pattern">{{ 'login.valid_email_msg' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('emailAddress')?.errors?.existingUser">{{ 'login.email_already_exist' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <app-single-select-dropdown class="country-code-field" [class]="'country-code-field white-field'" [dropdownControl]="secondFormGroup.get('dialCode')" [fieldLabel]="'login.dial_code' | translate"
                                        [options]="phoneCodeList" [labelKey]="'dial_code_with_iso3'" [labelValue]="'dial_code_with_iso3'" [isRequired]="false"
                                        [searchRequired]="true" appearance="fill">
                                    </app-single-select-dropdown>
                                    <mat-form-field class="white-field phone-number-field" appearance="fill">
                                        <mat-label>{{ 'profile.phone_number' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="phoneNumber" placeholder="e.g. 4975625479" maxlength="15">
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('phoneNumber')?.errors?.required">{{ 'profile.contact_required' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('phoneNumber')?.errors?.pattern">{{ 'profile.enter_valid_contact_no' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" *ngIf="userType === 'Private User'">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.birth_date' | translate }}</mat-label>
                                        <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="birthDate" readonly>
                                        <mat-hint>DD/MM/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('birthDate')?.errors?.required && !secondFormGroup.get('birthDate')?.errors?.matDatepickerParse">{{ 'login.birth_date_required' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('birthDate')?.errors?.matDatepickerParse">{{ 'login.birth_date_not_valid' | translate }}</span>
                                            <span *ngIf="secondFormGroup.get('birthDate')?.errors?.matDatepickerMin || secondFormGroup.get('birthDate')?.errors?.matDatepickerMax">{{ 'login.birth_date_range_not_valid' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" *ngIf="userType === 'Business User'">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.vat_number' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="vatNumber" maxlength="30">
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('vatNumber')?.errors?.pattern">{{ 'login.values_allowed_vat_no' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.payment_preference' | translate }}</mat-label>
                                        <mat-select multiple formControlName="paymentPreference" panelClass="white-field">
                                            <mat-option *ngFor="let preference of paymentPreferences" [value]="preference.code">{{ preference.name }}</mat-option>
                                        </mat-select>
                                        <mat-error>
                                            <span *ngIf="secondFormGroup.get('paymentPreference')?.errors?.required">{{ 'login.payment_preference_required' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4" *ngIf="isUserByReferral">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.referral_label' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="referralUserName" maxlength="50" [value]="referralUserDetails?.firstname + ' ' +  referralUserDetails?.lastname" readonly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <button type="button" class="btn tr-btn-secondary mr-3" matStepperPrevious>Back</button>
                                    <button type="button" class="btn tr-btn-primary" matStepperNext>Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="thirdFormGroup" label="{{ 'login.register_address_details' | translate }}">
                        <form [formGroup]="thirdFormGroup">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'profile.street' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="street" maxlength="30" required>
                                        <mat-error>
                                            <span *ngIf="thirdFormGroup.get('street')?.errors?.required">{{ 'profile.street_required' | translate }}</span>
                                            <span *ngIf="thirdFormGroup.get('street')?.errors?.pattern">{{ 'profile.values_allowed_street' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'profile.house_no' | translate }}</mat-label>
                                        <input type="text" matInput formControlName="houseNumber" maxlength="10" required>
                                        <mat-error>
                                            <span *ngIf="thirdFormGroup.get('houseNumber')?.errors?.required">{{ 'profile.house_no_required' | translate }}</span>
                                            <span *ngIf="thirdFormGroup.get('houseNumber')?.errors?.pattern">{{ 'profile.values_allowed_house_no' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <app-single-select-dropdown [dropdownControl]="thirdFormGroup.get('country')" [fieldLabel]="'profile.country' | translate"
                                        [options]="countryList" [labelKey]="'iso2'" [labelValue]="'name'" [class]="'white-field'" [isRequired]="true"
                                        [requiredErrorMsg]="'profile.country_required' | translate" [searchRequired]="true" appearance="fill" (onSelect)="onCountrySelect()">
                                    </app-single-select-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <app-single-select-dropdown [dropdownControl]="thirdFormGroup.get('state')" [fieldLabel]="'profile.state' | translate"
                                        [options]="stateList" [labelKey]="'iso2'" [labelValue]="'name'" [class]="'white-field'" [isRequired]="true"
                                        [requiredErrorMsg]="'profile.state_required' | translate" [searchRequired]="true" appearance="fill" (onSelect)="onStateSelect()">
                                    </app-single-select-dropdown>
                                </div>
                                <div class="col-md-4">
                                    <app-single-select-dropdown [dropdownControl]="thirdFormGroup.get('city')" [fieldLabel]="'profile.city' | translate"
                                        [options]="cityList" [labelKey]="'name'" [labelValue]="'name'" [class]="'white-field'" [isRequired]="true"
                                        [requiredErrorMsg]="'profile.city_required' | translate" [searchRequired]="true" appearance="fill" (onSelect)="onCitySelect()">
                                    </app-single-select-dropdown>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>{{ 'login.zip_code' | translate }}</mat-label>
                                        <input matInput aria-label="Number" formControlName="zipCode" (blur)="handleZipCodeValidation()" [matAutocomplete]="auto" maxlength="10" required>
                                        <mat-autocomplete #auto="matAutocomplete">
                                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{option}}
                                          </mat-option>
                                        </mat-autocomplete>
                                        <mat-error>
                                            <span *ngIf="thirdFormGroup.get('zipCode')?.errors?.required">{{ 'login.zip_code_required' | translate }}</span>
                                            <span *ngIf="thirdFormGroup.get('zipCode')?.errors?.notFromList">{{ 'login.zip_code_select_from_list' | translate }}</span>
                                            <span *ngIf="thirdFormGroup.get('zipCode')?.errors?.pattern">{{ 'login.enter_valid_zipcode' | translate }}</span>
                                        </mat-error>
                                      </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>Additional Information</mat-label>
                                        <textarea matInput formControlName="additionalInfo" rows="4" maxlength="500"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <button type="button" class="btn tr-btn-secondary mr-3" matStepperPrevious>Back</button>
                                    <button type="button" class="btn tr-btn-primary" matStepperNext>Next</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="fourthFormGroup" label="{{ 'login.register_set_password' | translate }}">
                        <form [formGroup]="fourthFormGroup">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>Password</mat-label>
                                        <input type="password" matInput formControlName="password" minlength="8" maxlength="24" required>
                                        <mat-error>
                                            <span *ngIf="fourthFormGroup.get('password')?.errors?.required">{{ 'login.password_required_warning' | translate }}</span>
                                            <span *ngIf="!fourthFormGroup.get('password')?.errors?.required && !fourthFormGroup.get('password')?.errors?.validatePassword?.valid">{{ 'login.password_not_as_per_policy' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="white-field" appearance="fill">
                                        <mat-label>Confirm Password</mat-label>
                                        <input type="password" matInput formControlName="confirmPassword" (change)="validateConfirmPassword()" required>
                                        <mat-error>
                                            <span *ngIf="fourthFormGroup.get('confirmPassword')?.errors?.required">{{ 'profile.confirm_password_required' | translate }}</span>
                                            <span *ngIf="!fourthFormGroup.get('confirmPassword')?.errors?.required && !fourthFormGroup.get('confirmPassword')?.errors?.passwordMatch">{{ 'profile.password_match' | translate }}</span>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <mat-label>Policy:</mat-label>
                                    <ul>
                                        <li>
                                            <mat-label class="policy-text">Password length should be minimum 8 characters and maximum 24 characters.</mat-label>
                                        </li>
                                        <li>
                                            <mat-label class="policy-text">Password should be a mix of uppercase (A-Z), lowercase (a-z), numbers (0-9) and special characters (!, @, #, $, %, ^, &, *, (, ), +, -, =, _).</mat-label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <mat-checkbox class="terms-conditions" formControlName="termsConditions"></mat-checkbox>
                                    <span class="terms-conditions-text" (click)="tncOverlay = true">Terms and Conditions / data security</span>
                                    <div class="error-msg" *ngIf="formSubmitted && !fourthFormGroup.get('termsConditions')?.value">{{ 'login.tc_Warning' | translate }}</div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <button type="button" class="btn tr-btn-secondary mr-3" matStepperPrevious>Back</button>
                                    <button type="submit" class="btn tr-btn-primary" (click)="onSubmit()">Submit</button>
                                </div>
                            </div>
                        </form>
                    </mat-step>
                </mat-stepper>
            </div>
        </div>
        <div class="social-login d-flex">
            <div></div>
            <div class="d-flex">
                <button type="button" class="btn tr-btn-primary mb-10" (click)="googleSignIn()">{{ 'login.connect_with_google' | translate }}</button>
                <button type="button" class="btn tr-btn-primary" (click)="fbSignIn()">{{ 'login.connect_with_facebook' | translate }}</button>
            </div>
            <div></div>
        </div>
    </div>
</div>
<div class="sell-wine-dialog" *ngIf="tncOverlay">
    <div class="modal fade show sell-wine tr-modal" tabindex="-1" role="dialog" aria-labelledby="sell wine"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content tr-shadow">
          <div class="modal-header">
            <h5 class="modal-title">{{ 'login.terms_conditions' | translate }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="tncOverlay = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="modelText">
                <app-terms-conditions></app-terms-conditions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>