<ng-adsense [adClient]="'ca-pub-1898450716362915'" [pageLevelAds]="true"></ng-adsense>
<div class="tr-main-wrapper" [ngClass]="{ 'public-theme': !authStatus }">
  <header *ngIf="viewLoaded">
    <app-header></app-header>
  </header>
  <main [ngClass]="{ 'public-page-container': !authStatus || !viewLoaded }" *ngIf="viewLoaded">
    <div class="page-container" [ngClass]="{'mb-0': noMarginBottom}">
      <router-outlet></router-outlet>
    </div>
  </main>
  <hr class="m-0 footer-line" *ngIf="!authStatus">
  <footer *ngIf="viewLoaded" id="footer">
    <div class="tr-footer">
      <div class="container-fluid py-3 d-none">
        <div class="row d-flex justify-content-between align-items-center">
          <div class="col-4 text-center d-flex justify-content-center">
            <span>Copyright &copy; 2022. All rights reserved</span>
          </div>
          <div class="col-4 text-center">
            <img src="../assets/img/TresorVino-Logo-purple.png" class="footer-logo" *ngIf="authStatus">
          </div>
          <div class="col-4 text-center">
            <ul class="footer-links d-flex justify-content-center flex-column flex-md-row">
              <li>
                <a routerLink="/privacy-policy">
                  Datenschutz
                </a>
              </li>
              <li class="d-none d-md-inline-block">|</li>
              <li>
                <a routerLink="/imprint">
                  Impresssum
                </a>
              </li>
              <li class="d-none d-md-inline-block">|</li>
              <li>
                <a routerLink="/terms-and-conditions">
                  AGBs
                </a>
              </li>
              <li>
                <a routerLink="/faqs">
                  FAQs
                </a>
              </li>
            </ul>
            <ul class="footer-links d-flex justify-content-center flex-column flex-md-row"
            [ngClass]="{ 'footerLinksLight' : !authStatus}">
              <li>
                <a routerLink="/privacy-policy">
                  Datenschutz
                </a>
              </li>
              <li class="d-none d-md-inline-block">|</li>
              <li>
                <a routerLink="/imprint">
                  Impresssum
                </a>
              </li>
              <li class="d-none d-md-inline-block">|</li>
              <li>
                <a routerLink="/terms-and-conditions">
                  AGBs
                </a>
              </li>
              <li>
                <a routerLink="/faqs">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <a [routerLink]="['/home']" class="d-block text-center">
          <img src="../assets/img/TresorVino-Logo-white.png" class="footer-logo">
        </a>
        <div class="footer-content d-flex justify-content-between">
          <div class="left-side">
            <span class="font-bold">baroxx Projektmanagement GmbH</span>
            <br>
            <span>Am Büchsenackerhang</span>
            <br>
            <span>66 69118 Heidelberg Amtsgericht</span>
            <br>
            <span>Mannheim HRB 741659</span>
          </div>
          <div class="right-side">
            <ul class="footer-links d-flex justify-content-center flex-column"
            [ngClass]="{ 'footerLinksLight' : true }">
              <li>
                <a routerLink="/privacy-policy">
                  {{ 'common.privacy_policy' | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/imprint">
                  Impresssum
                </a>
              </li>
              <li>
                <a routerLink="/terms-and-conditions">
                  AGBs
                </a>
              </li>
              <li>
                <a routerLink="/faqs">
                  FAQs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <app-share *ngIf="authStatus"></app-share>
  <app-toast></app-toast>
  <app-cookie-consent></app-cookie-consent>
  <app-level-up [userlevelData]="levelUpData" [allLevelsData]="levelsData" (onClosePopup)="closeLevelUpPopup()" *ngIf="showLevelUpPopup"></app-level-up>
  <app-vino-coins (onClosePopup)="closeVinoCoinPopup()" *ngIf="showVinoPopup"></app-vino-coins>
  <app-session-warning-popup (onContinueSession)="keepLoggedIn()" (onLogoutUser)="onLogOut()" *ngIf="showSessionModal"></app-session-warning-popup>
</div>
