import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripePaymentService {

  constructor(
    private http: HttpClient, 
    private apollo: Apollo,
  ) { }

  getHeaderContent() {
    const headerObj = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + environment.payment.stripe.secretKey, 
    }
    return headerObj;
  }


  getCustomerAccount(boardingAccountId: any) {
    const headers = new HttpHeaders(this.getHeaderContent());
    return this.http.get<any>(`https://api.stripe.com/v1/accounts/${boardingAccountId}`, { headers });
  }

  getSessionDetails(paymentSessionId: string) {
    const headers = new HttpHeaders(this.getHeaderContent());
    return this.http.get<any>(`https://api.stripe.com/v1/checkout/sessions/${paymentSessionId}`, { headers });
  }

  getPaymentReferenceDetails(paymentIntentId: string) {
    const headers = new HttpHeaders(this.getHeaderContent());
    return this.http.get<any>(`https://api.stripe.com/v1/payment_intents/${paymentIntentId}`, { headers })
  }

  getPaymentMethodDetails(PaymentId: string) {
    const headers = new HttpHeaders(this.getHeaderContent());
    return this.http.get<any>(`https://api.stripe.com/v1/payment_methods/${PaymentId}`, { headers })
  }

  getCustomerCognitoData(friendId: string) {
    return this.apollo.watchQuery<any>({
      query: gql`
        query MyQuery($user_id: ID!) {
          getStripeID(id: $user_id) {
            user_id
            stripe_id
          }
        }`,
      variables: {
        user_id: friendId
      }
    });
  }

  setPaymentDetails(paymentDetails: any) {
    return this.apollo.mutate<any>({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation MyMutation($amount_in_eur: Float! $vat_amount: Float! $func_cd: String! $payment_id: String! $prev_bal: Float! $transaction_type: String! $status: String! $payment_method: String $payment_error: String $payment_reference: String $payment_reference_number: String, $vat_amount_in_eur: Float!) {
          addVcTransactionHistory(  
            input: {
              amount_in_eur: $amount_in_eur, 
              vat_amount: $vat_amount,
              func_cd: $func_cd, 
              payment_id: $payment_id,
              prev_bal: $prev_bal, 
              transaction_type: $transaction_type, 
              status: $status,
              payment_method: $payment_method,
              payment_error: $payment_error,
              payment_reference: $payment_reference,
              payment_reference_number: $payment_reference_number,
              vat_amount_in_eur: $vat_amount_in_eur
            }
          ){
            amount_in_eur
            cur_bal
            func_cd
            payment_id
            prev_bal
            timestamp
            transaction_type
            user_id
            status
            vino_coins
          }
        }`,
      variables: {
        amount_in_eur: paymentDetails.amount_in_eur, 
        vat_amount: paymentDetails.vat_amount, 
        cur_bal: paymentDetails.cur_bal, 
        func_cd: paymentDetails.func_cd, 
        payment_id: paymentDetails.payment_id,
        prev_bal: paymentDetails.prev_bal, 
        transaction_type: paymentDetails.transaction_type,
        status: paymentDetails.status,
        payment_method: paymentDetails.payment_method,
        payment_error: paymentDetails.payment_error,
        payment_reference: paymentDetails.payment_reference,
        payment_reference_number: paymentDetails.payment_reference_number,
        vat_amount_in_eur: paymentDetails.vat_amount_in_eur
      }
    });
  }
}
