
<div class="modal fade show session-warning-modal tr-modal" tabindex="-1" role="dialog" aria-labelledby="sessionModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-center">
                <h5 class="modal-title" id="sessionModalTitle">
                    {{ 'common.session_warning' | translate }}
                </h5>
            </div>
            <div class="modal-body">
                <div class="d-flex flex-column align-items-center">
                    <div>{{ 'common.automatic_logout_in' | translate }}</div>
                    <div class="counter font-bold d-flex">
                        {{ countdownDisplay }}
                    </div>
                    <div>{{ 'common.need_more_time' | translate }}</div>
                </div>
            </div>
            <div class="modal-footer d-flex align-items-center justify-content-center">
                <button type="button" class="btn tr-btn-primary" data-dismiss="modal" (click)="keepLoggedIn()">{{ 'common.yes' | translate }}</button>
                <button type="button" class="btn tr-btn-secondary" data-dismiss="modal" (click)="onLogOut()">{{ 'common.no' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="backdrop"></div>
